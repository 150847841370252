<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Village Name</label>
                          <v-select
                            v-model="village"
                            placeholder="None"
                            label="villagename"
                            :options="villageOption"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Legal Status</label>
                          <v-select
                            v-model="status"
                            label="title"
                            placeholder="None"
                            :options="legalOption"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            value=""
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            placeholder="Select Date"
                            style="background-color: transparent"
                            @input="getdata($event)"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="searchData"
                        :disabled="loading"
                      >
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <customer-modal
                v-if="$store.state.app.customerDetailsModal"
              ></customer-modal>
              <report-table :data="data" :columns="fields">
                <template #customField="{ props }">
                  <span v-if="props.column.field == 'customer'">
                    <div
                      @click="
                        $store.dispatch('app/getCustomerDetails', props.row.customer)
                      "
                      class="text-primary cursor-pointer"
                    >
                      {{ props.row.customer ? props.row.customer.fullname : "" }}
                    </div>
                  </span>
                </template>
              </report-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import moment from "moment";
import { BFormGroup, BForm, BRow, BCol, BAvatar, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BAvatar,
    GoodTableColumnSearch,
  },
  data() {
    return {
      loading: false,
      customer: "",
      status: "",
      startdate: "",
      enddate: "",
      result: "",
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "villagename",
          label: "Village",
        },
        {
          field: "surveynonew",
          label: "New survey no.",
        },
        {
          field: "surveynoold",
          label: "Old survey no.",
        },
        {
          field: "date",
          label: "Purchase Date",
        },
        {
          field: "legal_date",
          label: "Legal status change date",
        },
        {
          field: "status",
          label: "Legal Status",
        },
      ],
      legalOption: [
        {
          title: "Entry",
          id: 0,
        },
        {
          title: "Bana Chhiththi Draft",
          id: 1,
        },
        {
          title: "Title Form For Signature of Farmer",
          id: 2,
        },
        {
          title: "Security Report",
          id: 3,
        },
        {
          title: "Publication in News Paper",
          id: 4,
        },
        {
          title: "Revenue Records Documents",
          id: 5,
        },
        {
          title: "Agreement to Sale",
          id: 6,
        },
        {
          title: "Submission of NA Application",
          id: 7,
        },
        {
          title: "Title Report-Final",
          id: 8,
        },
        {
          title: "Doc -> Query of Title Report",
          id: 9,
        },
        {
          title: "Id of Buyer for Appointment Schedule",
          id: 10,
        },
        {
          title: "Sale Deed for Execution",
          id: 11,
        },
        {
          title: "POA (Power of Attorney)",
          id: 12,
        },
        {
          title: "Uploading of Executed Docs",
          id: 13,
        },
        {
          title: "Citation of Entries",
          id: 14,
        },
        {
          title: "Confirmation of Buyer (for received documents)",
          id: 15,
        },
      ],
      villageOption: [],
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      leadCategory: "",
    };
  },

  watch: {
    "$store.state.master.lands"(newValue, oldValue) {
      this.handleLands();
    },
  },
  mounted() {
    this.$store.dispatch("master/getLands");
  },
  methods: {
    handleLands() {
      let allLands = this.$store.state.master.lands.map((item) => item.villagename);
      this.villageOption = Array.from(new Set(allLands));
    },
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result.length > 0 ? this.result[0] : "";
      this.enddate = this.result.length == 2 ? this.result[1] : "";
    },
    async searchData() {
      const data = {
        village: this.village,
        status: this.status ? this.status.id : "",
        startdate: this.startdate ? moment(this.startdate, "DD/MM/YYYY").format() : "",
        enddate: this.enddate ? moment(this.enddate, "DD/MM/YYYY").format() : "",
      };
      this.loading = true;
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/purchase-legalreport`,
      })
        .then((response) => {
          this.data = response.data.data;
          this.data.map((item) => {
            item.villagename = item.villagename?.villagename;
            let status = this.legalOption.filter(
              (inner) => inner.id == item.current_status?.status
            );
            item.status = status.length ? status[0].title : this.legalOption[0].title;
            item.legal_date = item.current_status
              ? moment(item.current_status.enterdatetime).format("DD/MM/YYYY")
              : "-";
            item.date = moment(item.date).format("DD/MM/YYYY");
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
